export default function setModule(request, defaultModule) {
  // defaultModule => "ZNAP_PL_PLANNING"

  let endpoint = request.url;
  let module = "";

  switch (true) {
    case endpoint.indexOf("user/menu") !== -1:
      module = defaultModule;
      break;
    case endpoint.indexOf("message") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("user/hash/login") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("event-status-by-user/") !== -1:
      module = defaultModule;
      break;
    case endpoint.indexOf("user/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("user/edit") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("user/list-options") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("user-group/mass-effect") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("client-module/list/client") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("module/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("module-origin/list-options") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("group/list") !== -1 &&
      endpoint.indexOf("security") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("transaction/list") !== -1 &&
      endpoint.indexOf("security") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("planning-function/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("approval-flow/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("approval-flow-level/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("notification/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("notification/edit") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("notification/delete") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("event-type/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("approval-flow-cost-center/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("planning-group-version/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("planning-group/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("/cost-center/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("pl-consolidation-status/list") !== -1:
      module = defaultModule;
      break;
    case endpoint.indexOf("status/list") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("chart-account/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("currency/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("unit-measurement/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("product/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("fiscal-year") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("event-calendar/list") !== -1:
      module = defaultModule;
      break;
    case endpoint.indexOf("calendar/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("company-group") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("customer-group-type") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("account-data-type") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("account-type") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("chart-account-version/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("company/list") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("sales-channel/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("/sales-force/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("/sales-force-type/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("/sales-channel-type/list") !== -1:
      module = "ZNAP_SALES_PLANNING";
      break;
    case endpoint.indexOf("/product") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("/plant/list-options") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("/purchase") !== -1:
      module = "ZNAP_PURCHASE_PLANNING";
      break;
    default:
      module = defaultModule;
  }

  return module;
}
